import React from 'react';
import { Col } from 'reactstrap';
import './right.css'

import remera1 from '../images/remera1.jpg';
import remera2 from '../images/remera2.jpg';


const Right = () => (
        <div>    
            <div className="row">
                <p className="texto">
                    <b>
                        Participando te podes ganar unas remeras geniales como las que se ven a continuación.
                    </b>
                </p>
            </div>
            <div className="row">
                <Col>
                        <img src={remera1} alt="images" height="100" width="100" />
            </Col>
                <Col>
                    <img src={remera2} alt="images" height="100" width="100" />
            </Col> 
            </div>         
        </div>
);



export default Right;
