import React, { Component } from 'react';

import './App.css';
import Header from './header/header';
import GetPost from './posts/getposts';
import Right   from './right/right';
import Left   from './left/left';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="row">
            <Header />     
        </div>
        <div className="row">      
             <div className="col-2"><Left/></div>        
             <div className="col-7"><GetPost /></div>
             <div className="col-3"><Right/></div>
        </div>
      </div>
    );
  }
}

export default App;
