import React from 'react';
import {  Col } from 'reactstrap';
 

import logo from '../images/yosoyyosoy-600dpi.png';

 

const Header = ( ) => (
    <Col sm="12">
     <img src={logo} alt="Logo" />
    </Col> 
);

 

export default Header;
