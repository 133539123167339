import React, { Component } from 'react';
import posts from './posts.js';
 

import { Card, CardSubtitle, ButtonGroup, CardBody, CardText, Row } from 'reactstrap';
 

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  InstapaperShareButton,
  InstapaperIcon
} from 'react-share';



function formatDateView(date){
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year ].join('-');
}



function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

class getposts extends Component {



  constructor(props) {
    super(props);
    this.state = {
      posts: posts
    };
  }

  render() {
    const { posts } = this.state;

    var post_view = []; 

    posts.forEach(function (post) {
      if (post.date <= formatDate(Date.now())) {
        post_view.push(post);
      }
    }); 

    var url = "http://www.yosoyyosoy.ar/";
    return (

      <div>
        <ul>
        
          {
            post_view.reverse().map(function (post, i) {

              return (
          
                <Row   key={post.id}>    
                    <Card className="col-12">                       
                      <CardBody>   
                      <CardSubtitle><b>{formatDateView(post.date)}</b></CardSubtitle>
                        <CardText>{ '"' +  post.frase + '"'}</CardText>
                        <ButtonGroup>
                          <FacebookShareButton
                            url={url}                           
                            quote={post.frase}
                            className="social-icon">
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>

                          <TwitterShareButton
                            url={url}
                            title={`${post.frase}`}
                            className="social-icon"
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>

                          <WhatsappShareButton
                            url={url}
                            title={`${post.frase}`}
                            className="social-icon"
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>

                          
                        </ButtonGroup>
                      </CardBody>
                    </Card>                 
                </Row>
               
                
              );
             
            })
          }
           
        </ul>
      </div>


    );
  }

}

export default getposts;

