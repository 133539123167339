const posts = [
    {
      "id": 0, 
      "date":   "2019-03-04",
      "frase":  "A pessimist sees the difficulty in every opportunity; an optimist sees the opportunity in every difficulty.El pesimista ve una dificultad en toda oportunidad; el optimista ve una oportunidad en toda dificultad. Sir Winston Churchill"
       
    }, 
    {
      "id": 1,   
      "date":   "2019-03-11",       
      "frase":  "Felicidad no es hacer lo que uno quiere sino querer lo que uno hace. Jean Paul Sartre"
      
    }
    , 
    {
      "id": 2,   
      "date":   "2019-03-25",       
      "frase":  "Switch the Focus from Blaming Others to Understanding Yourself. Dr. Wayne W. Dyer" 
       
    } 
  ,
  {
    "id": 3,
    "date": "2019-04-01",
    "frase": "Solo dos errores se pueden cometer en el camino hacia la verdad... no terminar o no comenzar. Buddha// There are two mistakes one can make along the road to truth… not going all the way, and not starting. Buddha"

  },
  {
	  
	  "id": 4,
    "date": "2019-04-08",
    "frase": "Si no te gusta algo, cambialo. Si no podes cambiarlo, cambia tu actitud. Dr. Maya Angelou.//If you don't like something, change it. If you can't change it, change your attitude. -Dr. Maya Angelou"
  },
   {
      "id": 9, 
      "date":   "2019-02-25",
      "frase":  "A pessimist sees the difficulty in every opportunity; an optimist sees the opportunity in every difficulty.//El pesimista ve una dificultad en toda oportunidad; el optimista ve una oportunidad en toda dificultad. Sir Winston Churchill"  
    }, 
    {
      "id": 10,   
      "date":   "2019-03-04",       
      "frase":  "Felicidad no es hacer lo que uno quiere sino querer lo que uno hace. Jean Paul Sartre"    
    }, 
    {
      "id": 11,   
      "date":   "2019-03-11",       
      "frase":  "Switch the Focus from Blaming Others to Understanding Yourself. Dr. Wayne W. Dyer" 
    },
    {
      "id": 12,   
      "date":   "2019-03-18",       
      "frase":  "Tu cuerpo es el carruaje, las riendas los pensamientos, los caballos los sentimientos y tu, el chofer. Platon." 
    },
  {
    "id": 13,
    "date": "2019-03-25",
    "frase": "Solo dos errores se pueden cometer en el camino hacia la verdad... no terminar o no comenzar. Buddha// There are two mistakes one can make along the road to truth… not going all the way, and not starting. Buddha"
  },
  {
    "id": 14,
    "date": "2019-04-01",
    "frase": "La vida es aquello que te va sucediendo mientras estas ocupado haciendo otros planes. John Lennon"
  },
  {
    "id": 15,
    "date": "2019-04-08",
    "frase": "Si no te gusta algo, cambialo. Si no podes cambiarlo, cambia tu actitud. Dr. Maya Angelou.//If you don't like something, change it. If you can't change it, change your attitude. -Dr. Maya Angelou"
  },
  {
    "id": 16,
    "date": "2019-04-15",
    "frase": "Lo más importante en un momento es ser capaz de sacrificar lo que eres por lo que serás. - Charles DuBois"
  },
  {
    "id": 17,
    "date": "2019-04-22",
    "frase": "Si fallas en prepararte, estas preparandote para fallar.//By failing to prepare, you are preparing to fail. Benjamin Franklin"
  },
  {
    "id": 18,
    "date": "2019-04-29",
    "frase": "Being deeply loved by someone gives you strength, while loving someone deeply gives you courage. - Lao Tzu"
  },
  {
    "id": 19,
    "date": "2019-05-06",
    "frase": "Ama la verdad, pero perdona el error. Voltaire"
  },
  {
    "id": 20,
    "date": "2019-05-13",
    "frase": "Juzga tu exito en funcion de aquello a lo que has renunciado para conseguirlo."
  },
  {
    "id": 21,
    "date": "2019-05-20",
    "frase": "El éxito es más consecuencia de una actitud que de una capacidad."
  },
  {
    "id": 22,
    "date": "2019-05-27",
    "frase": "No esperes el momento perfecto. Abraza al momento y hazlo perfecto."
  },
  {
    "id": 23,
    "date": "2019-06-03",
    "frase": "Nadie puede hacerte infeliz sin tu consentimiento."
  },
  {
    "id": 24,
    "date": "2019-06-10",
    "frase": "Si alguna vez no te dan una sonrisa esperada, se generoso y da la tuya, porque nadie tiene tanta necesidad de una sonrisa como aquel que no sabe sonreir. Mahatma Gandhi"
  },
  {
    "id": 25,
    "date": "2019-06-17",
    "frase": "Disfruta la vida tan intensamente de forma tal de recordarla en la proxima."
  },
  {
    "id": 26,
    "date": "2019-06-24",
    "frase": "Errar es humano, espiritual es reconocer nuestras limitaciones."
  },
  {
    "id": 27,
    "date": "2019-07-01",
    "frase": "Cuanto menos haces, menos realizas. Cuanto mas realizas, mas haces."
  },
  {
    "id": 28,
    "date": "2019-07-08",
    "frase": "No se quien fue mi abuelo; me importa mucho mas saber lo que su nieto sera. Abraham Lincoln."
  },
  {
    "id": 29,
    "date": "2019-07-15",
    "frase": "Muchos buscan la felicidad como otros buscan el sombrero: lo llevan encima y no se dan cuenta. - Nikolaus Lenau"
  },
  {
    "id": 30,
    "date": "2019-07-22",
    "frase": "Aprendi que la gente, eventualmente, se olvidara de lo que dijiste, tambien de lo que hiciste, pero nunca de como los hiciste sentir. - Maya Angelou"
  },
  {
    "id": 31,
    "date": "2019-07-29",
    "frase": "Nuestro ser no tiene precio, tiene valor. El precio lo ponen los demas, el valor, nosotros mismos."
  },
  {
    "id": 32,
    "date": "2019-08-05",
    "frase": "La sonrisa cuesta menos que la electricidad y da mas luz."
  },
  {
    "id": 33,
    "date": "2019-08-12",
    "frase": "El buen humor hace que todas las cosas sean tolerables. - Henry Ward Beecher"
  },
  {
    "id": 34,
    "date": "2019-08-19",
    "frase": "Hope is wishing something good would happen. Faith is believing something good would happen. Courage is making something good would happen."
  },
  {
    "id": 35,
    "date": "2019-08-26",
    "frase": "No siempre consiste la constancia en hacer las mismas cosas, sino las que se dirigen al mismo fin - Luis XIV"
  },
  {
    "id": 36,
    "date": "2019-09-02",
    "frase": "Always remember, you have within you the strength, the patience, and the passion to reach for the stars to change the world. – Eleanor Roosevelt."
  },
  {
    "id": 37,
    "date": "2019-09-09",
    "frase": "La felicidad no se busca, es en su busqueda que se es feliz. Todos tenemos esa obligacion"
  },
  {
    "id": 38,
    "date": "2019-09-16",
    "frase": "The greatest test of courage on earth is to bear defeat without losing heart. - Robert Green Ingersoll"
  },
  {
    "id": 39,
    "date": "2019-09-23",
    "frase": "Failure isn't fatal, but failure to change might be.//Fallar no es fatal, pero fallar en cambiar podria serlo.- Juan Amaderado."
  },
  {
    "id": 40,
    "date": "2019-09-30",
    "frase": "Siempre tienes el poder de manejar sabia y sanamente, la emocion que cualquier situacion te genera. O sea... Acepta la realidad y administra su impacto."
  },
  {
    "id": 41,
    "date": "2019-10-07",
    "frase": "Lo que no veas con tus ojos no lo inventes con tu boca."
  },
  {
    "id": 42,
    "date": "2019-10-14",
    "frase": "40 son los musculos necesarions para que tu frente se arrugue, solo 15 trabajan en tu sonrisa"
  },
  {
    "id": 43,
    "date": "2019-10-21",
    "frase": "No a los atajos. Vale la pena andar todo el camino hasta que la esperada recompensa llegue.//No shortcuts. The whole road is worth living until the hoped reward arrives."
  },
  {
    "id": 44,
    "date": "2019-10-28",
    "frase": "In life you are pilot or a passenger, it´s your choice."
  },
  {
    "id": 45,
    "date": "2019-11-04",
    "frase": "A veces pretendo ser normal, pero me aburre, asi que vuelvo a ser yo."
  },
  {
    "id": 46,
    "date": "2019-11-11",
    "frase": "Tropezar no es malo, encariñarse con la piedra si."
  },
  {
    "id": 47,
    "date": "2019-11-18",
    "frase": "No existe una almohada mas comoda que una conciencia limpia."
  },
  {
    "id": 48,
    "date": "2019-11-25",
    "frase": "Olvida tus debilidades, piensa en el coraje."
  },
  {
    "id": 49,
    "date": "2019-12-02",
    "frase": "Lo que soy lo pienso, como estoy lo decido, con quien estar lo elijo, el cuando llega, el donde ubica y el por que no importa."
  },
  {
    "id": 50,
    "date": "2019-12-09",
    "frase": "No quiero ser exitoso, quiero tener exito  siendo."
  },
  {
    "id": 51,
    "date": "2019-12-16",
    "frase": "Uno conserva lo que no amarra."
  },
  {
    "id": 52,
    "date": "2019-12-23",
    "frase": "No se trata de cuan fuerte pegas, sino de cuan fuerte te pegan y seguir para adelante. Asi es como se gana!!! // ...it ain't about how hard you hit. It's about how hard you can get hit and keep moving forward...That's how winning is done! - Rocky Balboa"
  },
  {
    "id": 101, 
    "date": "2020-01-01",
    "frase": "We will open the book. Its pages are blank. We are going to put words on them ourselves. The book is called Opportunity and its first chapter is New Year's Day. ― Edith Lovejoy Pierce"
  }, 
  {
    "id": 102, 
    "date":   "2020-01-08",
    "frase":  "No cuentes los días, haz que los días cuenten. Muhammad Ali"  
  }, 
  {
    "id": 103,   
    "date":   "2020-01-15",       
    "frase":  "Es sencillo ser feliz. Lo dificil es ser sencillo - Mario San Miguel" 
  },
  {
    "id": 104, 
    "date":   "2020-01-22",
    "frase":  "No es que no consigamos hacer las cosas porque sean difíciles; son difíciles porque no conseguimos hacerlas. (Séneca)"  
  }, 
  {
    "id": 105, 
    "date":   "2020-01-29",
    "frase":  "It feels good to be lost in the right direction."  
  }, 
  {
    "id": 106,   
    "date":   "2020-02-05",       
    "frase":  "Nunca te rindas tratando de hacer lo que realmente deseas hacer. Donde hay amor e inspiración, nada te puede salir mal- Ella Fitzgerald." 
  },
{
  "id": 107,
  "date": "2020-02-12",
  "frase": "El secreto de salir adelante es comenzar-Mark Twain"
},
{
  "id": 108,
  "date": "2020-02-19",
  "frase": "El modo en que vemos el problema es el problema - Stephen R. Covey"
},
{
  "id": 109,
  "date": "2020-02-26",
  "frase": "El único lugar en el cual éxito viene antes de trabajo es en el diccionario – Vincent Lombardi"
},
  {
    "id": 110, 
    "date":   "2020-03-04",
    "frase":  "No one has ever become poor by giving- Anne Frank, diary of Anne Frank"  
  }, 
  {
    "id": 111, 
    "date":   "2020-03-11",
    "frase":  "I am thankful for my struggle because without it, I wouldn't have stumbled upon my strength. Alexandra Elle"  
  }, 
  {
    "id": 112,   
    "date":   "2020-03-18",       
    "frase":  "Always remember, you have within you the strength, the patience, and the passion to reach for the stars to change the world. – Eleanor Roosevelt" 
  },
{
  "id": 121,
  "date": "2020-03-25",
  "frase": "Un amigo es alguien que puede ver la verdad y el dolor en ti aunque supongas estar engañando a todos."
},
{
  "id": 131,
  "date": "2020-04-01",
  "frase": "The most difficult thing is the decision to act, the rest is merely tenacity. Amelia Earhart"
},
{
  "id": 141,
  "date": "2020-04-08",
  "frase": "Lo que hace más importante a tu rosa, es el tiempo que tú has perdido con ella. - Antoine de Saint-Exupery - Le Petit Prince"
},
{
  "id": 151,
  "date": "2020-04-15",
  "frase": "Work hard, have fun, be nice, play fair, dream big!"
},
{
  "id": 161,
  "date": "2020-04-22",
  "frase": "Si dejas salir todos tus miedos, tendrás mas espacio para vivir todos tus sueños - Marilyn Monroe"
},
{
  "id": 171,
  "date": "2020-04-29",
  "frase": "La felicidad no se busca, es en su busqueda que se es feliz. Todos tenemos esa obligacion"
},
{
  "id": 181,
  "date": "2020-05-06",
  "frase": "Do not follow where the path may lead. Go instead where there is no path and leave a trail – Ralph Waldo Emerson"
},
{
  "id": 191,
  "date": "2020-05-13",
  "frase": "CUANTO MAS PURA SEA TU MIRADA, TANTO MAS SEGURA SERA LA TRAVESIA ANTE LAS MULTIPLES TEMPESTADES. MI VIEJA"
},
{
  "id": 211,
  "date": "2020-05-20",
  "frase": "Los buenos amigos no te dejan hacer estupideces...sin ellos..."
},
{
  "id": 221,
  "date": "2020-05-27",
  "frase": "The love of family and the admiration of friends is much more important than wealth and privilege. - Charles Kuralt"
},
{
  "id": 231,
  "date": "2020-06-03",
  "frase": "Iré a cualquier parte, siempre que sea hacia adelante– Dr. Livingstone."
},
{
  "id": 241,
  "date": "2020-06-10",
  "frase": "Never Give Up on something you cant go a day without thinking about"
},
{
  "id": 251,
  "date": "2020-06-17",
  "frase": "Disfruta la vida tan intensamente de forma tal de recordarla en la proxima."
},
{
  "id": 261,
  "date": "2020-06-24",
  "frase": "Errar es humano, espiritual es reconocer nuestras limitaciones."
},
{
  "id": 271,
  "date": "2020-07-01",
  "frase": "Cuanto menos haces, menos realizas. Cuanto mas realizas, mas haces."
},
{
  "id": 281,
  "date": "2020-07-08",
  "frase": "No se quien fue mi abuelo; me importa mucho mas saber lo que su nieto sera. Abraham Lincoln."
},
{
  "id": 291,
  "date": "2020-07-15",
  "frase": "Felicidad no es hacer lo que uno quiere sino querer lo que uno hace. Jean Paul Sartre"
},
{
  "id": 301,
  "date": "2020-07-22",
  "frase": "Siempre quise ser alguien, pero ahora me doy cuenta que deberia haber sido mas especifico"
},
{
  "id": 311,
  "date": "2020-07-29",
  "frase": "Nuestro ser no tiene precio, tiene valor. El precio lo ponen los demas, el valor, nosotros mismos."
},
{
  "id": 321,
  "date": "2020-08-05",
  "frase": "Changes in latitudes changes in attitudes.- Wasting Away In Margaritaville - Jamaica"
},
{
  "id": 331,
  "date": "2020-08-12",
  "frase": "Nothing is real if u don't believe in who you are. - From Rocky 3 Movie"
},
{
  "id": 341,
  "date": "2020-08-19",
  "frase": "If you’ve got nothing to dance about, find a reason to sing.- Melody Carstairs"
},
{
  "id": 351,
  "date": "2020-08-26",
  "frase": "La altura de tu exito depende de la profundidad de tu deseo."
},
{
  "id": 361,
  "date": "2020-09-02",
  "frase": "Be yourself; everyone else is already taken.Oscar Wilde."	
},	
{
  "id": 371,
  "date": "2020-09-09",
  "frase": "Sentir gratitud y no expresarla es como envolver un regalo y no darlo.- Guillermo Arturo"
},
{
  "id": 381,
  "date": "2020-09-16",
  "frase": "True forgiveness is when you can say, ‘Thank you for that experience.’ - Oprah Winfrey"
},
{
  "id": 391,
  "date": "2020-09-23",
  "frase": "No sabia que ponerme y me puse feliz."
},
{
  "id": 401,
  "date": "2020-09-30",
  "frase": "Success is not final, failure is not fatal: it is the courage to continue that counts. Winston Churchill"
},
{
  "id": 411,
  "date": "2020-10-07",
  "frase": "Es dificil decir que es imposible, ya que el sueño del ayer es la esperanza de hoy, y la realidad del mañana. Robert Goddard."
},
{
  "id": 421,
  "date": "2020-10-14",
  "frase": "If you concentrate on what you don't have, you will never, ever have enough. - Oprah Winfrey"
},
{
  "id": 431,
  "date": "2020-10-21",
  "frase": "No esperes las condiciones perfectas para comenzar. Comenzar hace a las condiciones, perfectas. - Alan Cohen"
},
{
  "id": 441,
  "date": "2020-10-28",
  "frase": "Escucha a tu voz interior y sigue adelante, aún cuando las personas te digan que no puedes hacerlo- Mary Lou Cook"
},
{
  "id": 451,
  "date": "2020-11-04",
  "frase": "La diferencia entre ganar y perder, frecuentemente, es no rendirse. Walt Disney"
},
{
  "id": 461,
  "date": "2020-11-11",
  "frase": "Tropezar no es malo, encariñarse con la piedra si."
},
{
  "id": 471,
  "date": "2020-11-18",
  "frase": "No existe una almohada mas comoda que una conciencia limpia."
},
{
  "id": 481,
  "date": "2020-11-25",
  "frase": "La gente que piensa que se las sabe todas, son una re-molestia para nosotros que la tenemos re clara. Isaac Asimov"
},
{
  "id": 491,
  "date": "2020-12-02",
  "frase": "Lo que soy lo pienso, como estoy lo decido, con quien estar lo elijo, el cuando llega, el donde ubica y el por que no importa."
},
{
  "id": 501,
  "date": "2020-12-09",
  "frase": "No quiero ser exitoso, quiero tener exito  siendo."
},
{
  "id": 511,
  "date": "2020-12-16",
  "frase": "Uno conserva lo que no amarra."
},
{
  "id": 521,
  "date": "2020-12-23",
  "frase": "No se trata de cuan fuerte pegas, sino de cuan fuerte te pegan y seguir para adelante. Asi es como se gana!!! // ...it ain't about how hard you hit. It's about how hard you can get hit and keep moving forward...That's how winning is done! - Rocky Balboa"
},
{
  "id": 522,
  "date": "2020-12-30",
  "frase": "Another fresh new year is here. Another year to live! To banish worry, doubt, and fear, to love and laugh and give! William Arthur Ward"
},
  {
    "id": 101,
    "date": "2021-01-01",
    "frase": "We will open the book. Its pages are blank. We are going to put words on them ourselves. The book is called Opportunity and its first chapter is New Year's Day. ― Edith Lovejoy Pierce"
    },
  {
    "id": 102,
    "date": "2021-01-08",
    "frase": "No cuentes los días, haz que los días cuenten. Muhammad Ali"  
    },
  {
    "id": 103,
    "date": "2021-01-15",
    "frase": "Es sencillo ser feliz. Lo dificil es ser sencillo - Mario San Miguel " 
    },
  {
    "id": 104,
    "date": "2021-01-22",
    "frase": "No es que no consigamos hacer las cosas porque sean difíciles; son difíciles porque no conseguimos hacerlas. (Séneca)"  
    },
  {
    "id": 105,
    "date": "2021-01-29",
    "frase": "It feels good to be lost in the right direction."  
    },
  {
    "id": 106,
    "date": "2021-02-05",
    "frase": "Nunca te rindas tratando de hacer lo que realmente deseas hacer. Donde hay amor e inspiración, nada te puede salir mal- Ella Fitzgerald." 
    },
  {
    "id": 107,
    "date": "2021-02-12",
    "frase": "El secreto de salir adelante es comenzar-Mark Twain"
  },
  {
    "id": 108,
    "date": "2021-02-19",
    "frase": "El modo en que vemos el problema es el problema - Stephen R. Covey"
  },
  {
    "id": 109,
    "date": "2021-02-26",
    "frase": "El único lugar en el cual éxito viene antes de trabajo es en el diccionario – Vincent Lombardi"
  },
  {
    "id": 110,
    "date": "2021-03-04",
    "frase": "No one has ever become poor by giving- Anne Frank, diary of Anne Frank"  
    },
  {
    "id": 111,
    "date": "2021-03-11",
    "frase": "I am thankful for my struggle because without it, I wouldn't have stumbled upon my strength. Alexandra Elle"  
    },
  {
    "id": 112,
    "date": "2021-03-18",
    "frase": "Always remember, you have within you the strength, the patience, and the passion to reach for the stars to change the world. – Eleanor Roosevelt" 
    },
  {
    "id": 121,
    "date": "2021-03-25",
    "frase": "Un amigo es alguien que puede ver la verdad y el dolor en ti aunque supongas estar engañando a todos."
  },
  {
    "id": 131,
    "date": "2021-04-01",
    "frase": "The most difficult thing is the decision to act, the rest is merely tenacity. Amelia Earhart"
  },
  {
    "id": 141,
    "date": "2021-04-08",
    "frase": "Lo que hace más importante a tu rosa, es el tiempo que tú has perdido con ella. - Antoine de Saint-Exupery - Le Petit Prince"
  },
  {
    "id": 151,
    "date": "2021-04-15",
    "frase": "Work hard, have fun, be nice, play fair, dream big!"
  },
  {
    "id": 161,
    "date": "2021-04-22",
    "frase": "Si dejas salir todos tus miedos, tendrás mas espacio para vivir todos tus sueños - Marilyn Monroe"
  },
  {
    "id": 171,
    "date": "2021-04-29",
    "frase": "La felicidad no se busca, es en su busqueda que se es feliz. Todos tenemos esa obligacion"
  },
  {
    "id": 181,
    "date": "2021-05-06",
    "frase": "Do not follow where the path may lead. Go instead where there is no path and leave a trail – Ralph Waldo Emerson"
  },
  {
    "id": 191,
    "date": "2021-05-13",
    "frase": "CUANTO MAS PURA SEA TU MIRADA, TANTO MAS SEGURA SERA LA TRAVESIA ANTE LAS MULTIPLES TEMPESTADES. MI VIEJA"
  },
  {
    "id": 211,
    "date": "2021-05-20",
    "frase": "Los buenos amigos no te dejan hacer estupideces...sin ellos..."
  },
  {
    "id": 221,
    "date": "2021-05-27",
    "frase": "The love of family and the admiration of friends is much more important than wealth and privilege. - Charles Kuralt"
  },
  {
    "id": 231,
    "date": "2021-06-03",
    "frase": "Iré a cualquier parte, siempre que sea hacia adelante– Dr. Livingstone."
  },
  {
    "id": 241,
    "date": "2021-06-10",
    "frase": "Never Give Up on something you cant go a day without thinking about"
  },
  {
    "id": 251,
    "date": "2021-06-17",
    "frase": "Disfruta la vida tan intensamente de forma tal de recordarla en la proxima."
  },
  {
    "id": 261,
    "date": "2021-06-24",
    "frase": "Errar es humano, espiritual es reconocer nuestras limitaciones."
  },
  {
    "id": 271,
    "date": "2021-07-01",
    "frase": "Cuanto menos haces, menos realizas. Cuanto mas realizas, mas haces."
  },
  {
    "id": 281,
    "date": "2021-07-08",
    "frase": "No se quien fue mi abuelo; me importa mucho mas saber lo que su nieto sera. Abraham Lincoln."
  },
  {
    "id": 291,
    "date": "2021-07-15",
    "frase": "Felicidad no es hacer lo que uno quiere sino querer lo que uno hace. Jean Paul Sartre"
  },
  {
    "id": 301,
    "date": "2021-07-22",
    "frase": "Siempre quise ser alguien, pero ahora me doy cuenta que deberia haber sido mas especifico"
  },
  {
    "id": 311,
    "date": "2021-07-29",
    "frase": "Nuestro ser no tiene precio, tiene valor. El precio lo ponen los demas, el valor, nosotros mismos."
  },
  {
    "id": 321,
    "date": "2021-08-05",
    "frase": "Changes in latitudes changes in attitudes.- Wasting Away In Margaritaville - Jamaica"
  },
  {
    "id": 331,
    "date": "2021-08-12",
    "frase": "Nothing is real if u don't believe in who you are. - From Rocky 3 Movie"
  },
  {
    "id": 341,
    "date": "2021-08-19",
    "frase": "If you’ve got nothing to dance about, find a reason to sing.- Melody Carstairs"
  },
  {
    "id": 351,
    "date": "2021-08-26",
    "frase": "La altura de tu exito depende de la profundidad de tu deseo."
  },
  {
    "id": 361,
    "date": "2021-09-02",
    "frase": "Be yourself; everyone else is already taken.Oscar Wilde."
    },		
  {
      "id": 371,
      "date": "2021-09-09",
      "frase": "Sentir gratitud y no expresarla es como envolver un regalo y no darlo.- Guillermo Arturo"
  },
  {
    "id": 381,
    "date": "2021-09-16",
    "frase": "True forgiveness is when you can say, ‘Thank you for that experience.’ - Oprah Winfrey"
  },
  {
    "id": 391,
    "date": "2021-09-23",
    "frase": "No sabia que ponerme y me puse feliz."
  },
  {
    "id": 401,
    "date": "2021-09-30",
    "frase": "Success is not final, failure is not fatal: it is the courage to continue that counts. Winston Churchill"
  },
  {
    "id": 411,
    "date": "2021-10-07",
    "frase": "Es dificil decir que es imposible, ya que el sueño del ayer es la esperanza de hoy, y la realidad del mañana. Robert Goddard."
  },
  {
    "id": 421,
    "date": "2021-10-14",
    "frase": "If you concentrate on what you don't have, you will never, ever have enough. - Oprah Winfrey"
  },
  {
    "id": 431,
    "date": "2021-10-21",
    "frase": "No esperes las condiciones perfectas para comenzar. Comenzar hace a las condiciones, perfectas. - Alan Cohen"
  },
  {
    "id": 441,
    "date": "2021-10-28",
    "frase": "Escucha a tu voz interior y sigue adelante, aún cuando las personas te digan que no puedes hacerlo- Mary Lou Cook"
  },
  {
    "id": 451,
    "date": "2021-11-04",
    "frase": "La diferencia entre ganar y perder, frecuentemente, es no rendirse. Walt Disney"
  },
  {
    "id": 461,
    "date": "2021-11-11",
    "frase": "Tropezar no es malo, encariñarse con la piedra si."
  },
  {
    "id": 471,
    "date": "2021-11-18",
    "frase": "No existe una almohada mas comoda que una conciencia limpia."
  },
  {
    "id": 481,
    "date": "2021-11-25",
    "frase": "La gente que piensa que se las sabe todas, son una re-molestia para nosotros que la tenemos re clara. Isaac Asimov"
  },
  {
    "id": 491,
    "date": "2021-12-02",
    "frase": "Lo que soy lo pienso, como estoy lo decido, con quien estar lo elijo, el cuando llega, el donde ubica y el por que no importa."
  },
  {
    "id": 501,
    "date": "2021-12-09",
    "frase": "No quiero ser exitoso, quiero tener exito  siendo."
  },
  {
    "id": 511,
    "date": "2021-12-16",
    "frase": "Uno conserva lo que no amarra."
  },
  {
    "id": 521,
    "date": "2021-12-23",
    "frase": "No se trata de cuan fuerte pegas, sino de cuan fuerte te pegan y seguir para adelante. Asi es como se gana!!! // ...it ain't about how hard you hit. It's about how hard you can get hit and keep moving forward...That's how winning is done! - Rocky Balboa"
  },
  {
    "id": 522,
    "date": "2021-12-30",
    "frase": "Another fresh new year is here. Another year to live! To banish worry, doubt, and fear, to love and laugh and give! William Arthur Ward"
  },
  {
      "id": 101, 
      "date":   "2022-01-01",
      "frase": "We will open the book. Its pages are blank. We are going to put words on them ourselves. The book is called Opportunity and its first chapter is New Year's Day. ― Edith Lovejoy Pierce"
    }, 
    {
      "id": 102, 
      "date":   "2022-01-08",
      "frase":  "No cuentes los días, haz que los días cuenten. Muhammad Ali"  
    }, 
    {
      "id": 103,   
      "date":   "2022-01-15",       
      "frase":  "Es sencillo ser feliz. Lo dificil es ser sencillo - Mario San Miguel " 
    },
    {
      "id": 104, 
      "date":   "2022-01-22",
      "frase":  "No es que no consigamos hacer las cosas porque sean difíciles; son difíciles porque no conseguimos hacerlas. (Séneca)"  
    }, 
    {
      "id": 105, 
      "date":   "2022-01-29",
      "frase":  "It feels good to be lost in the right direction."  
    }, 
    {
      "id": 106,   
      "date":   "2022-02-05",       
      "frase":  "Nunca te rindas tratando de hacer lo que realmente deseas hacer. Donde hay amor e inspiración, nada te puede salir mal- Ella Fitzgerald." 
    },
  {
    "id": 107,
    "date": "2022-02-12",
    "frase": "El secreto de salir adelante es comenzar-Mark Twain"
  },
  {
    "id": 108,
    "date": "2022-02-19",
    "frase": "El modo en que vemos el problema es el problema - Stephen R. Covey"
  },
  {
    "id": 109,
    "date": "2022-02-26",
    "frase": "El único lugar en el cual éxito viene antes de trabajo es en el diccionario – Vincent Lombardi"
  },
    {
      "id": 110, 
      "date":   "2022-03-04",
      "frase":  "No one has ever become poor by giving- Anne Frank, diary of Anne Frank"  
    }, 
    {
      "id": 111, 
      "date":   "2022-03-11",
      "frase":  "I am thankful for my struggle because without it, I wouldn't have stumbled upon my strength. Alexandra Elle"  
    }, 
    {
      "id": 112,   
      "date":   "2022-03-18",       
      "frase":  "Always remember, you have within you the strength, the patience, and the passion to reach for the stars to change the world. – Eleanor Roosevelt" 
    },
  {
    "id": 121,
    "date": "2022-03-25",
    "frase": "Un amigo es alguien que puede ver la verdad y el dolor en ti aunque supongas estar engañando a todos."
  },
  {
    "id": 131,
    "date": "2022-04-01",
    "frase": "The most difficult thing is the decision to act, the rest is merely tenacity. Amelia Earhart"
  },
  {
    "id": 141,
    "date": "2022-04-08",
    "frase": "Lo que hace más importante a tu rosa, es el tiempo que tú has perdido con ella. - Antoine de Saint-Exupery - Le Petit Prince"
  },
  {
    "id": 151,
    "date": "2022-04-15",
    "frase": "Work hard, have fun, be nice, play fair, dream big!"
  },
  {
    "id": 161,
    "date": "2022-04-22",
    "frase": "Si dejas salir todos tus miedos, tendrás mas espacio para vivir todos tus sueños - Marilyn Monroe"
  },
  {
    "id": 171,
    "date": "2022-04-29",
    "frase": "La felicidad no se busca, es en su busqueda que se es feliz. Todos tenemos esa obligacion"
  },
  {
    "id": 181,
    "date": "2022-05-06",
    "frase": "Do not follow where the path may lead. Go instead where there is no path and leave a trail – Ralph Waldo Emerson"
  },
  {
    "id": 191,
    "date": "2022-05-13",
    "frase": "CUANTO MAS PURA SEA TU MIRADA, TANTO MAS SEGURA SERA LA TRAVESIA ANTE LAS MULTIPLES TEMPESTADES. MI VIEJA"
  },
  {
    "id": 211,
    "date": "2022-05-20",
    "frase": "Los buenos amigos no te dejan hacer estupideces...sin ellos..."
  },
  {
    "id": 221,
    "date": "2022-05-27",
    "frase": "The love of family and the admiration of friends is much more important than wealth and privilege. - Charles Kuralt"
  },
  {
    "id": 231,
    "date": "2022-06-03",
    "frase": "Iré a cualquier parte, siempre que sea hacia adelante– Dr. Livingstone."
  },
  {
    "id": 241,
    "date": "2022-06-10",
    "frase": "Never Give Up on something you cant go a day without thinking about"
  },
  {
    "id": 251,
    "date": "2022-06-17",
    "frase": "Disfruta la vida tan intensamente de forma tal de recordarla en la proxima."
  },
  {
    "id": 261,
    "date": "2022-06-24",
    "frase": "Errar es humano, espiritual es reconocer nuestras limitaciones."
  },
  {
    "id": 271,
    "date": "2022-07-01",
    "frase": "Cuanto menos haces, menos realizas. Cuanto mas realizas, mas haces."
  },
  {
    "id": 281,
    "date": "2022-07-08",
    "frase": "No se quien fue mi abuelo; me importa mucho mas saber lo que su nieto sera. Abraham Lincoln."
  },
  {
    "id": 291,
    "date": "2022-07-15",
    "frase": "Felicidad no es hacer lo que uno quiere sino querer lo que uno hace. Jean Paul Sartre"
  },
  {
    "id": 301,
    "date": "2022-07-22",
    "frase": "Siempre quise ser alguien, pero ahora me doy cuenta que deberia haber sido mas especifico"
  },
  {
    "id": 311,
    "date": "2022-07-29",
    "frase": "Nuestro ser no tiene precio, tiene valor. El precio lo ponen los demas, el valor, nosotros mismos."
  },
  {
    "id": 321,
    "date": "2022-08-05",
    "frase": "Changes in latitudes changes in attitudes.- Wasting Away In Margaritaville - Jamaica"
  },
  {
    "id": 331,
    "date": "2022-08-12",
    "frase": "Nothing is real if u don't believe in who you are. - From Rocky 3 Movie"
  },
  {
    "id": 341,
    "date": "2022-08-19",
    "frase": "If you’ve got nothing to dance about, find a reason to sing.- Melody Carstairs"
  },
  {
    "id": 351,
    "date": "2022-08-26",
    "frase": "La altura de tu exito depende de la profundidad de tu deseo."
  },
  {
    "id": 361,
    "date": "2022-09-02",
    "frase": "Be yourself; everyone else is already taken.Oscar Wilde."		
     },
  {
    "id": 371,
    "date": "2022-09-09",
    "frase": "Sentir gratitud y no expresarla es como envolver un regalo y no darlo.- Guillermo Arturo"
    
  },
  {
    "id": 381,
    "date": "2022-09-16",
    "frase": "True forgiveness is when you can say, ‘Thank you for that experience.’ - Oprah Winfrey"
  },
  {
    "id": 391,
    "date": "2022-09-23",
    "frase": "No sabia que ponerme y me puse feliz."
  },
  {
    "id": 401,
    "date": "2022-09-30",
    "frase": "Success is not final, failure is not fatal: it is the courage to continue that counts. Winston Churchill"
  },
  {
    "id": 411,
    "date": "2022-10-07",
    "frase": "Es dificil decir que es imposible, ya que el sueño del ayer es la esperanza de hoy, y la realidad del mañana. Robert Goddard."
  },
  {
    "id": 421,
    "date": "2022-10-14",
    "frase": "If you concentrate on what you don't have, you will never, ever have enough. - Oprah Winfrey"
  },
  {
    "id": 431,
    "date": "2022-10-21",
    "frase": "No esperes las condiciones perfectas para comenzar. Comenzar hace a las condiciones, perfectas. - Alan Cohen"
  },
  {
    "id": 441,
    "date": "2022-10-28",
    "frase": "Escucha a tu voz interior y sigue adelante, aún cuando las personas te digan que no puedes hacerlo- Mary Lou Cook"
  },
  {
    "id": 451,
    "date": "2022-11-04",
    "frase": "La diferencia entre ganar y perder, frecuentemente, es no rendirse. Walt Disney"
  },
  {
    "id": 461,
    "date": "2022-11-11",
    "frase": "Tropezar no es malo, encariñarse con la piedra si."
  },
  {
    "id": 471,
    "date": "2022-11-18",
    "frase": "No existe una almohada mas comoda que una conciencia limpia."
  },
  {
    "id": 481,
    "date": "2022-11-25",
    "frase": "La gente que piensa que se las sabe todas, son una re-molestia para nosotros que la tenemos re clara. Isaac Asimov"
  },
  {
    "id": 491,
    "date": "2022-12-02",
    "frase": "Lo que soy lo pienso, como estoy lo decido, con quien estar lo elijo, el cuando llega, el donde ubica y el por que no importa."
  },
  {
    "id": 501,
    "date": "2022-12-09",
    "frase": "No quiero ser exitoso, quiero tener exito  siendo."
  },
  {
    "id": 511,
    "date": "2022-12-16",
    "frase": "Uno conserva lo que no amarra."
  },
  {
    "id": 521,
    "date": "2022-12-23",
    "frase": "No se trata de cuan fuerte pegas, sino de cuan fuerte te pegan y seguir para adelante. Asi es como se gana!!!  ...it ain't about how hard you hit. It's about how hard you can get hit and keep moving forward...That's how winning is done! - Rocky Balboa"
  },
  {
    "id": 522,
    "date": "2022-12-30",
    "frase": "Another fresh new year is here. Another year to live! To banish worry, doubt, and fear, to love and laugh and give! William Arthur Ward"
  },
  {
      "id": 101, 
      "date":   "2023-01-01",
      "frase": "We will open the book. Its pages are blank. We are going to put words on them ourselves. The book is called Opportunity and its first chapter is New Year's Day. ― Edith Lovejoy Pierce"
    }, 
    {
      "id": 102, 
      "date":   "2023-01-08",
      "frase":  "No cuentes los días, haz que los días cuenten. Muhammad Ali"  
    }, 
    {
      "id": 103,   
      "date":   "2023-01-15",       
      "frase":  "Es sencillo ser feliz. Lo dificil es ser sencillo - Mario San Miguel " 
    },
    {
      "id": 104, 
      "date":   "2023-01-22",
      "frase":  "No es que no consigamos hacer las cosas porque sean difíciles; son difíciles porque no conseguimos hacerlas. (Séneca)"  
    }, 
    {
      "id": 105, 
      "date":   "2023-01-29",
      "frase":  "It feels good to be lost in the right direction."  
    }, 
    {
      "id": 106,   
      "date":   "2023-02-05",       
      "frase":  "Nunca te rindas tratando de hacer lo que realmente deseas hacer. Donde hay amor e inspiración, nada te puede salir mal- Ella Fitzgerald." 
    },
  {
    "id": 107,
    "date": "2023-02-12",
    "frase": "El secreto de salir adelante es comenzar-Mark Twain"
  },
  {
    "id": 108,
    "date": "2023-02-19",
    "frase": "El modo en que vemos el problema es el problema - Stephen R. Covey"
  },
  {
    "id": 109,
    "date": "2023-02-26",
    "frase": "El único lugar en el cual éxito viene antes de trabajo es en el diccionario – Vincent Lombardi"
  },
    {
      "id": 110, 
      "date":   "2023-03-04",
      "frase":  "No one has ever become poor by giving- Anne Frank, diary of Anne Frank"  
    }, 
    {
      "id": 111, 
      "date":   "2023-03-11",
      "frase":  "I am thankful for my struggle because without it, I wouldn't have stumbled upon my strength. Alexandra Elle"  
    }, 
    {
      "id": 112,   
      "date":   "2023-03-18",       
      "frase":  "Always remember, you have within you the strength, the patience, and the passion to reach for the stars to change the world. – Eleanor Roosevelt" 
    },
  {
    "id": 121,
    "date": "2023-03-25",
    "frase": "Un amigo es alguien que puede ver la verdad y el dolor en ti aunque supongas estar engañando a todos."
  },
  {
    "id": 131,
    "date": "2023-04-01",
    "frase": "The most difficult thing is the decision to act, the rest is merely tenacity. Amelia Earhart"
  },
  {
    "id": 141,
    "date": "2023-04-08",
    "frase": "Lo que hace más importante a tu rosa, es el tiempo que tú has perdido con ella. - Antoine de Saint-Exupery - Le Petit Prince"
  },
  {
    "id": 151,
    "date": "2023-04-15",
    "frase": "Work hard, have fun, be nice, play fair, dream big!"
  },
  {
    "id": 161,
    "date": "2023-04-22",
    "frase": "Si dejas salir todos tus miedos, tendrás mas espacio para vivir todos tus sueños - Marilyn Monroe"
  },
  {
    "id": 171,
    "date": "2023-04-29",
    "frase": "La felicidad no se busca, es en su busqueda que se es feliz. Todos tenemos esa obligacion"
  },
  {
    "id": 181,
    "date": "2023-05-06",
    "frase": "Do not follow where the path may lead. Go instead where there is no path and leave a trail – Ralph Waldo Emerson"
  },
  {
    "id": 191,
    "date": "2023-05-13",
    "frase": "CUANTO MAS PURA SEA TU MIRADA, TANTO MAS SEGURA SERA LA TRAVESIA ANTE LAS MULTIPLES TEMPESTADES. MI VIEJA"
  },
  {
    "id": 211,
    "date": "2023-05-20",
    "frase": "Los buenos amigos no te dejan hacer estupideces...sin ellos..."
  },
  {
    "id": 221,
    "date": "2023-05-27",
    "frase": "The love of family and the admiration of friends is much more important than wealth and privilege. - Charles Kuralt"
  },
  {
    "id": 231,
    "date": "2023-06-03",
    "frase": "Iré a cualquier parte, siempre que sea hacia adelante– Dr. Livingstone."
  },
  {
    "id": 241,
    "date": "2023-06-10",
    "frase": "Never Give Up on something you cant go a day without thinking about"
  },
  {
    "id": 251,
    "date": "2023-06-17",
    "frase": "Disfruta la vida tan intensamente de forma tal de recordarla en la proxima."
  },
  {
    "id": 261,
    "date": "2023-06-24",
    "frase": "Errar es humano, espiritual es reconocer nuestras limitaciones."
  },
  {
    "id": 271,
    "date": "2023-07-01",
    "frase": "Cuanto menos haces, menos realizas. Cuanto mas realizas, mas haces."
  },
  {
    "id": 281,
    "date": "2023-07-08",
    "frase": "No se quien fue mi abuelo; me importa mucho mas saber lo que su nieto sera. Abraham Lincoln."
  },
  {
    "id": 291,
    "date": "2023-07-15",
    "frase": "Felicidad no es hacer lo que uno quiere sino querer lo que uno hace. Jean Paul Sartre"
  },
  {
    "id": 301,
    "date": "2023-07-22",
    "frase": "Siempre quise ser alguien, pero ahora me doy cuenta que deberia haber sido mas especifico"
  },
  {
    "id": 311,
    "date": "2023-07-29",
    "frase": "Nuestro ser no tiene precio, tiene valor. El precio lo ponen los demas, el valor, nosotros mismos."
  },
  {
    "id": 321,
    "date": "2023-08-05",
    "frase": "Changes in latitudes changes in attitudes.- Wasting Away In Margaritaville - Jamaica"
  },
  {
    "id": 331,
    "date": "2023-08-12",
    "frase": "Nothing is real if u don't believe in who you are. - From Rocky 3 Movie"
  },
  {
    "id": 341,
    "date": "2023-08-19",
    "frase": "If you’ve got nothing to dance about, find a reason to sing.- Melody Carstairs"
  },
  {
    "id": 351,
    "date": "2023-08-26",
    "frase": "La altura de tu exito depende de la profundidad de tu deseo."
  },
  {
    "id": 361,
    "date": "2023-09-02",
    "frase": "Be yourself; everyone else is already taken.Oscar Wilde."		
  },
  {
    "id": 371,
    "date": "2023-09-09",
    "frase": "Sentir gratitud y no expresarla es como envolver un regalo y no darlo.- Guillermo Arturo"
  
  },
  {
    "id": 381,
    "date": "2023-09-16",
    "frase": "True forgiveness is when you can say, ‘Thank you for that experience.’ - Oprah Winfrey"
  },
  {
    "id": 391,
    "date": "2023-09-23",
    "frase": "No sabia que ponerme y me puse feliz."
  },
  {
    "id": 401,
    "date": "2023-09-30",
    "frase": "Success is not final, failure is not fatal: it is the courage to continue that counts. Winston Churchill"
  },
  {
    "id": 411,
    "date": "2023-10-07",
    "frase": "Es dificil decir que es imposible, ya que el sueño del ayer es la esperanza de hoy, y la realidad del mañana. Robert Goddard."
  },
  {
    "id": 421,
    "date": "2023-10-14",
    "frase": "If you concentrate on what you don't have, you will never, ever have enough. - Oprah Winfrey"
  },
  {
    "id": 431,
    "date": "2023-10-21",
    "frase": "No esperes las condiciones perfectas para comenzar. Comenzar hace a las condiciones, perfectas. - Alan Cohen"
  },
  {
    "id": 441,
    "date": "2023-10-28",
    "frase": "Escucha a tu voz interior y sigue adelante, aún cuando las personas te digan que no puedes hacerlo- Mary Lou Cook"
  },
  {
    "id": 451,
    "date": "2023-11-04",
    "frase": "La diferencia entre ganar y perder, frecuentemente, es no rendirse. Walt Disney"
  },
  {
    "id": 461,
    "date": "2023-11-11",
    "frase": "Tropezar no es malo, encariñarse con la piedra si."
  },
  {
    "id": 471,
    "date": "2023-11-18",
    "frase": "No existe una almohada mas comoda que una conciencia limpia."
  },
  {
    "id": 481,
    "date": "2023-11-25",
    "frase": "La gente que piensa que se las sabe todas, son una re-molestia para nosotros que la tenemos re clara. Isaac Asimov"
  },
  {
    "id": 491,
    "date": "2023-12-02",
    "frase": "Lo que soy lo pienso, como estoy lo decido, con quien estar lo elijo, el cuando llega, el donde ubica y el por que no importa."
  },
  {
    "id": 501,
    "date": "2023-12-09",
    "frase": "No quiero ser exitoso, quiero tener exito  siendo."
  },
  {
    "id": 511,
    "date": "2023-12-16",
    "frase": "Uno conserva lo que no amarra."
  },
  {
    "id": 521,
    "date": "2023-12-23",
    "frase": "No se trata de cuan fuerte pegas, sino de cuan fuerte te pegan y seguir para adelante. Asi es como se gana!!! // ...it ain't about how hard you hit. It's about how hard you can get hit and keep moving forward...That's how winning is done! - Rocky Balboa"
  },
  {
    "id": 522,
    "date": "2023-12-30",
    "frase": "Another fresh new year is here. Another year to live! To banish worry, doubt, and fear, to love and laugh and give! William Arthur Ward"
  },
  {
      "id": 101, 
      "date":   "2024-01-01",
      "frase": "We will open the book. Its pages are blank. We are going to put words on them ourselves. The book is called Opportunity and its first chapter is New Year's Day. ― Edith Lovejoy Pierce"
    }, 
    {
      "id": 102, 
      "date":   "2024-01-08",
      "frase":  "No cuentes los días, haz que los días cuenten. Muhammad Ali"  
    }, 
    {
      "id": 103,   
      "date":   "2024-01-15",       
      "frase":  "Es sencillo ser feliz. Lo dificil es ser sencillo - Mario San Miguel" 
    },
    {
      "id": 104, 
      "date":   "2024-01-22",
      "frase":  "No es que no consigamos hacer las cosas porque sean difíciles; son difíciles porque no conseguimos hacerlas. (Séneca)"  
    }, 
    {
      "id": 105, 
      "date":   "2024-01-29",
      "frase":  "It feels good to be lost in the right direction."  
    }, 
    {
      "id": 106,   
      "date":   "2024-02-05",       
      "frase":  "Nunca te rindas tratando de hacer lo que realmente deseas hacer. Donde hay amor e inspiración, nada te puede salir mal- Ella Fitzgerald." 
    },
  {
    "id": 107,
    "date": "2024-02-12",
    "frase": "El secreto de salir adelante es comenzar-Mark Twain"
  },
  {
    "id": 108,
    "date": "2024-02-19",
    "frase": "El modo en que vemos el problema es el problema - Stephen R. Covey"
  },
  {
    "id": 109,
    "date": "2024-02-26",
    "frase": "El único lugar en el cual éxito viene antes de trabajo es en el diccionario – Vincent Lombardi"
  },
    {
      "id": 110, 
      "date":   "2024-03-04",
      "frase":  "No one has ever become poor by giving- Anne Frank, diary of Anne Frank"  
    }, 
    {
      "id": 111, 
      "date":   "2024-03-11",
      "frase":  "I am thankful for my struggle because without it, I wouldn't have stumbled upon my strength. Alexandra Elle"  
    }, 
    {
      "id": 112,   
      "date":   "2024-03-18",       
      "frase":  "Always remember, you have within you the strength, the patience, and the passion to reach for the stars to change the world. – Eleanor Roosevelt" 
    },
  {
    "id": 121,
    "date": "2024-03-25",
    "frase": "Un amigo es alguien que puede ver la verdad y el dolor en ti aunque supongas estar engañando a todos."
  },
  {
    "id": 131,
    "date": "2024-04-01",
    "frase": "The most difficult thing is the decision to act, the rest is merely tenacity. Amelia Earhart"
  },
  {
    "id": 141,
    "date": "2024-04-08",
    "frase": "Lo que hace más importante a tu rosa, es el tiempo que tú has perdido con ella. - Antoine de Saint-Exupery - Le Petit Prince"
  },
  {
    "id": 151,
    "date": "2024-04-15",
    "frase": "Work hard, have fun, be nice, play fair, dream big!"
  },
  {
    "id": 161,
    "date": "2024-04-22",
    "frase": "Si dejas salir todos tus miedos, tendrás mas espacio para vivir todos tus sueños - Marilyn Monroe"
  },
  {
    "id": 171,
    "date": "2024-04-29",
    "frase": "La felicidad no se busca, es en su busqueda que se es feliz. Todos tenemos esa obligacion"
  },
  {
    "id": 181,
    "date": "2024-05-06",
    "frase": "Do not follow where the path may lead. Go instead where there is no path and leave a trail – Ralph Waldo Emerson"
  },
  {
    "id": 191,
    "date": "2024-05-13",
    "frase": "CUANTO MAS PURA SEA TU MIRADA, TANTO MAS SEGURA SERA LA TRAVESIA ANTE LAS MULTIPLES TEMPESTADES. MI VIEJA"
  },
  {
    "id": 211,
    "date": "2024-05-20",
    "frase": "Los buenos amigos no te dejan hacer estupideces...sin ellos..."
  },
  {
    "id": 221,
    "date": "2024-05-27",
    "frase": "The love of family and the admiration of friends is much more important than wealth and privilege. - Charles Kuralt"
  },
  {
    "id": 231,
    "date": "2024-06-03",
    "frase": "You can't be afraid to fail. It's the only way you succeed. ― LeBron James"
  },
  {
    "id": 241,
    "date": "2024-06-10",
    "frase": "Never Give Up on something you cant go a day without thinking about"
  },
  {
    "id": 251,
    "date": "2024-06-17",
    "frase": "Disfruta la vida tan intensamente de forma tal de recordarla en la proxima."
  },
  {
    "id": 261,
    "date": "2024-06-24",
    "frase": "Errar es humano, espiritual es reconocer nuestras limitaciones."
  },
  {
    "id": 271,
    "date": "2024-07-01",
    "frase": "Cuanto menos haces, menos realizas. Cuanto mas realizas, mas haces."
  },
  {
    "id": 281,
    "date": "2024-07-08",
    "frase": "No se quien fue mi abuelo; me importa mucho mas saber lo que su nieto sera. Abraham Lincoln."
  },
  {
    "id": 291,
    "date": "2024-07-15",
    "frase": "Felicidad no es hacer lo que uno quiere sino querer lo que uno hace. Jean Paul Sartre"
  },
  {
    "id": 301,
    "date": "2024-07-22",
    "frase": "Siempre quise ser alguien, pero ahora me doy cuenta que deberia haber sido mas especifico"
  },
  {
    "id": 311,
    "date": "2024-07-29",
    "frase": "Nuestro ser no tiene precio, tiene valor. El precio lo ponen los demas, el valor, nosotros mismos."
  },
  {
    "id": 321,
    "date": "2024-08-05",
    "frase": "Changes in latitudes changes in attitudes.- Wasting Away In Margaritaville - Jamaica"
  },
  {
    "id": 331,
    "date": "2024-08-12",
    "frase": "Nothing is real if u don't believe in who you are. - From Rocky 3 Movie"
  },
  {
    "id": 341,
    "date": "2024-08-19",
    "frase": "If you’ve got nothing to dance about, find a reason to sing.- Melody Carstairs"
  },
  {
    "id": 351,
    "date": "2024-08-26",
    "frase": "La altura de tu exito depende de la profundidad de tu deseo."
  },
  {
    "id": 361,
    "date": "2024-09-02",
    "frase": "Be yourself; everyone else is already taken.Oscar Wilde."		
  },
  {
    "id": 371,
    "date": "2024-09-09",
    "frase": "Sentir gratitud y no expresarla es como envolver un regalo y no darlo.- Guillermo Arturo"
  },
  {
    "id": 381,
    "date": "2024-09-16",
    "frase": "True forgiveness is when you can say, ‘Thank you for that experience.’ - Oprah Winfrey"
  },
  {
    "id": 391,
    "date": "2024-09-23",
    "frase": "No sabia que ponerme y me puse feliz."
  },
  {
    "id": 401,
    "date": "2024-09-30",
    "frase": "Success is not final, failure is not fatal: it is the courage to continue that counts. Winston Churchill"
  },
  {
    "id": 411,
    "date": "2024-10-07",
    "frase": "La suerte es un accesorio del exito, no lo determina, la disciplina y perseverancia si."
  },
  {
    "id": 421,
    "date": "2024-10-14",
    "frase": "If you concentrate on what you don't have, you will never, ever have enough. - Oprah Winfrey"
  },
  {
    "id": 431,
    "date": "2024-10-21",
    "frase": "No esperes las condiciones perfectas para comenzar. Comenzar hace a las condiciones, perfectas. - Alan Cohen"
  },
  {
    "id": 441,
    "date": "2024-10-28",
    "frase": "Siempre hay tiempo para ponerse mal"
  },
  {
    "id": 451,
    "date": "2024-11-04",
    "frase": "La diferencia entre ganar y perder, frecuentemente, es no rendirse. Walt Disney"
  },
  {
    "id": 461,
    "date": "2024-11-11",
    "frase": "Tropezar no es malo, encariñarse con la piedra si."
  },
  {
    "id": 471,
    "date": "2024-11-18",
    "frase": "No existe una almohada mas comoda que una conciencia limpia."
  },
  {
    "id": 481,
    "date": "2024-11-25",
    "frase": "La gente que piensa que se las sabe todas, son una re-molestia para nosotros que la tenemos re clara. Isaac Asimov"
  },
  {
    "id": 491,
    "date": "2024-12-02",
    "frase": "Lo que soy lo pienso, como estoy lo decido, con quien estar lo elijo, el cuando llega, el donde ubica y el por que no importa."
  },
  {
    "id": 501,
    "date": "2024-12-09",
    "frase": "No quiero ser exitoso, quiero tener exito  siendo."
  },
  {
    "id": 511,
    "date": "2024-12-16",
    "frase": "Uno conserva lo que no amarra."
  },
  {
    "id": 521,
    "date": "2024-12-23",
    "frase": "No se trata de cuan fuerte pegas, sino de cuan fuerte te pegan y seguir para adelante. Asi es como se gana!!! // ...it ain't about how hard you hit. It's about how hard you can get hit and keep moving forward...That's how winning is done! - Rocky Balboa"
  },
  {
    "id": 522,
    "date": "2024-12-30",
    "frase": "Another fresh new year is here. Another year to live! To banish worry, doubt, and fear, to love and laugh and give! William Arthur Ward"
  },
  {
      "id": 523, 
      "date":   "2025-01-01",
      "frase": "We will open the book. Its pages are blank. We are going to put words on them ourselves. The book is called Opportunity and its first chapter is New Year's Day. ― Edith Lovejoy Pierce"
    }, 
    {
      "id": 524, 
      "date":   "2025-01-08",
      "frase":  "No cuentes los días, haz que los días cuenten. Muhammad Ali"
    }, 
    {
      "id": 525,   
      "date":   "2025-01-15",       
      "frase":  "Es sencillo ser feliz. Lo dificil es ser sencillo - Mario San Miguel"
    },
    {
      "id": 526, 
      "date":   "2025-01-22",
      "frase":  "No es que no consigamos hacer las cosas porque sean difíciles; son difíciles porque no conseguimos hacerlas. (Séneca)"  
    }, 
    {
      "id": 527, 
      "date":   "2025-01-29",
      "frase":  "It feels good to be lost in the right direction."  
    }, 
    {
      "id": 528,   
      "date":   "2025-02-05",       
      "frase":  "Nunca te rindas tratando de hacer lo que realmente deseas hacer. Donde hay amor e inspiración, nada te puede salir mal- Ella Fitzgerald." 
    },
  {
    "id": 529,
    "date": "2025-02-12",
    "frase": "El secreto de salir adelante es comenzar-Mark Twain"
  },
  {
    "id": 530,
    "date": "2025-02-19",
    "frase": "El modo en que vemos el problema es el problema - Stephen R. Covey"
  },
  {
    "id": 531,
    "date": "2025-02-26",
    "frase": "El único lugar en el cual éxito viene antes de trabajo es en el diccionario – Vincent Lombardi"
  },
    {
      "id": 532, 
      "date":   "2025-03-04",
      "frase":  "No one has ever become poor by giving- Anne Frank, diary of Anne Frank"  
    }, 
    {
      "id": 533, 
      "date":   "2025-03-11",
      "frase":  "I am thankful for my struggle because without it, I wouldn't have stumbled upon my strength. Alexandra Elle"  
    }, 
    {
      "id": 534,   
      "date":   "2025-03-18",       
      "frase":  "Always remember, you have within you the strength, the patience, and the passion to reach for the stars to change the world. – Eleanor Roosevelt" 
    },
  {
    "id": 535,
    "date": "2025-03-25",
    "frase": "Un amigo es alguien que puede ver la verdad y el dolor en ti aunque supongas estar engañando a todos."
  },
  {
    "id": 536,
    "date": "2025-04-01",
    "frase": "The most difficult thing is the decision to act, the rest is merely tenacity. Amelia Earhart"
  },
  {
    "id": 537,
    "date": "2025-04-08",
    "frase": "Lo que hace más importante a tu rosa, es el tiempo que tú has perdido con ella. - Antoine de Saint-Exupery - Le Petit Prince"
  },
  {
    "id": 538,
    "date": "2025-04-15",
    "frase": "Work hard, have fun, be nice, play fair, dream big!"
  },
  {
    "id": 539,
    "date": "2025-04-22",
    "frase": "Si dejas salir todos tus miedos, tendrás mas espacio para vivir todos tus sueños - Marilyn Monroe"
  },
  {
    "id": 540,
    "date": "2025-04-29",
    "frase": "La felicidad no se busca, es en su busqueda que se es feliz. Todos tenemos esa obligacion"
  },
  {
    "id": 541,
    "date": "2025-05-06",
    "frase": "Do not follow where the path may lead. Go instead where there is no path and leave a trail – Ralph Waldo Emerson"
  },
  {
    "id": 542,
    "date": "2025-05-13",
    "frase": "CUANTO MAS PURA SEA TU MIRADA, TANTO MAS SEGURA SERA LA TRAVESIA ANTE LAS MULTIPLES TEMPESTADES. MI VIEJA"
  },
  {
    "id": 543,
    "date": "2025-05-20",
    "frase": "Los buenos amigos no te dejan hacer estupideces...sin ellos..."
  },
  {
    "id": 544,
    "date": "2025-05-27",
    "frase": "The love of family and the admiration of friends is much more important than wealth and privilege. - Charles Kuralt"
  },
  {
    "id": 545,
    "date": "2025-06-03",
    "frase": "You can't be afraid to fail. It's the only way you succeed. ― LeBron James"
  },
  {
    "id": 546,
    "date": "2025-06-10",
    "frase": "Never Give Up on something you cant go a day without thinking about"
  },
  {
    "id": 547,
    "date": "2025-06-17",
    "frase": "Disfruta la vida tan intensamente de forma tal de recordarla en la proxima."
  },
  {
    "id": 548,
    "date": "2025-06-24",
    "frase": "Errar es humano, espiritual es reconocer nuestras limitaciones."
  },
  {
    "id": 549,
    "date": "2025-07-01",
    "frase": "Cuanto menos haces, menos realizas. Cuanto mas realizas, mas haces."
  },
  {
    "id": 550,
    "date": "2025-07-08",
    "frase": "No se quien fue mi abuelo; me importa mucho mas saber lo que su nieto sera. Abraham Lincoln."
  },
  {
    "id": 551,
    "date": "2025-07-15",
    "frase": "Felicidad no es hacer lo que uno quiere sino querer lo que uno hace. Jean Paul Sartre"
  },
  {
    "id": 552,
    "date": "2025-07-22",
    "frase": "Siempre quise ser alguien, pero ahora me doy cuenta que deberia haber sido mas especifico"
  },
  {
    "id": 553,
    "date": "2025-07-29",
    "frase": "Nuestro ser no tiene precio, tiene valor. El precio lo ponen los demas, el valor, nosotros mismos."
  },
  {
    "id": 554,
    "date": "2025-08-05",
    "frase": "Changes in latitudes changes in attitudes.- Wasting Away In Margaritaville - Jamaica"
  },
  {
    "id": 555,
    "date": "2025-08-12",
    "frase": "Nothing is real if u don't believe in who you are. - From Rocky 3 Movie"
  },
  {
    "id": 556,
    "date": "2025-08-19",
    "frase": "If you’ve got nothing to dance about, find a reason to sing.- Melody Carstairs"
  },
  {
    "id": 557,
    "date": "2025-08-26",
    "frase": "La altura de tu exito depende de la profundidad de tu deseo."
  },
  {
    "id": 558,
    "date": "2025-09-02",
    "frase": "Be yourself; everyone else is already taken.Oscar Wilde."		
    },
  {
    
    "id": 559,
    "date": "2025-09-09",
    "frase": "Sentir gratitud y no expresarla es como envolver un regalo y no darlo.- Guillermo Arturo"
  },
  {
    "id": 560,
    "date": "2025-09-16",
    "frase": "True forgiveness is when you can say, ‘Thank you for that experience.’ - Oprah Winfrey"
  },
  {
    "id": 561,
    "date": "2025-09-23",
    "frase": "No sabia que ponerme y me puse feliz."
  },
  {
    "id": 562,
    "date": "2025-09-30",
    "frase": "Success is not final, failure is not fatal: it is the courage to continue that counts. Winston Churchill"
  },
  {
    "id": 563,
    "date": "2025-10-07",
    "frase": "La suerte es un accesorio del exito, no lo determina, la disciplina y perseverancia si."
  },
  {
    "id": 564,
    "date": "2025-10-14",
    "frase": "If you concentrate on what you don't have, you will never, ever have enough. - Oprah Winfrey"
  },
  {
    "id": 565,
    "date": "2025-10-21",
    "frase": "No esperes las condiciones perfectas para comenzar. Comenzar hace a las condiciones, perfectas. - Alan Cohen"
  },
  {
    "id": 566,
    "date": "2025-10-28",
    "frase": "Siempre hay tiempo para ponerse mal"
  },
  {
    "id": 567,
    "date": "2025-11-04",
    "frase": "La diferencia entre ganar y perder, frecuentemente, es no rendirse. Walt Disney"
  },
  {
    "id": 568,
    "date": "2025-11-11",
    "frase": "Tropezar no es malo, encariñarse con la piedra si."
  },
  {
    "id": 569,
    "date": "2025-11-18",
    "frase": "No existe una almohada mas comoda que una conciencia limpia."
  },
  {
    "id": 570,
    "date": "2025-11-25",
    "frase": "La gente que piensa que se las sabe todas, son una re-molestia para nosotros que la tenemos re clara. Isaac Asimov"
  },
  {
    "id": 571,
    "date": "2025-12-02",
    "frase": "Lo que soy lo pienso, como estoy lo decido, con quien estar lo elijo, el cuando llega, el donde ubica y el por que no importa."
  },
  {
    "id": 572,
    "date": "2025-12-09",
    "frase": "No quiero ser exitoso, quiero tener exito  siendo."
  },
  {
    "id": 573,
    "date": "2025-12-16",
    "frase": "Uno conserva lo que no amarra."
  },
  {
    "id": 574,
    "date": "2025-12-23",
    "frase": "No se trata de cuan fuerte pegas, sino de cuan fuerte te pegan y seguir para adelante. Asi es como se gana!!! // ...it ain't about how hard you hit. It's about how hard you can get hit and keep moving forward...That's how winning is done! - Rocky Balboa"
  },
  {
    "id": 575,
    "date": "2025-12-30",
    "frase": "Another fresh new year is here. Another year to live! To banish worry, doubt, and fear, to love and laugh and give! William Arthur Ward"
  }
  
  
  
  
  
  ];
export default posts;