import React from 'react';
import './left.css'
import 'bootstrap/dist/css/bootstrap.min.css';


const Left = () => (
    <div>    
        <div className="row" class="spacer5">
            <p class="titulo">Sección descarga</p>
        </div>
        <div className="row" class="spacer5">
            <a href="../doc/45reglas.txt" target="_blank" class="btn btn-primary">45 reglas</a>
        </div>
        <div className="row" class="spacer5">
            <a href="../doc/agradecimientos.txt"   class="btn btn-primary" download>Agradecimientos</a>
        </div>
        <div className="row" class="spacer5">
            <a href="../doc/eleslabonperdido.txt"  class="btn btn-primary" download>El eslabon perdido</a>
        </div>
        <div className="row" class="spacer5">
            <a href="../doc/lateoriadelranking.txt"  class="btn btn-primary" download>La teoria del ranking</a>
        </div> 
        <div className="row" class="spacer5">
            <a href="../doc/LEYESVARIAS.doc"  class="btn btn-primary" download>Leyes varias</a>
        </div> 
        <div className="row" class="spacer5">
            <a href="../doc/noterindas.txt"  class="btn btn-primary" download>No te rindas</a>
        </div> 
        <div className="row" class="spacer5">
            <a href="../doc/thehappybook.txt"  class="btn btn-primary" download>The happy book</a>
        </div> 
        <div className="row" class="spacer5">
            <a href="../doc/tresenpareja.txt"  class="btn btn-primary" download>Tres en pareja</a>
        </div> 
    </div>
);



export default Left;